import React from 'react';
import './UseCase.css';
import icon1 from '../../assets/images/twitter-icon.png';
import icon2 from '../../assets/images/linkedin-icon.png';

function UseCase() {
  return (
    <section className="container-uc">
      <div className="overlay-uc"></div>
      <div className="inner-container-uc">
		<h2 className='title-uc'>Use case</h2>			
        <div className='grid-uc'>
					<div className='grid-item-uc grid-item-uc-1'>
						<div className='grid-item-left-uc'>
							<h3>Community Building</h3>
							<p>Host meetings, events, and more in a virtual space where every user is real.</p>
						</div>
						<div className='grid-item-img-uc img-1-uc'></div>
					</div>
					
					<div className='grid-item-uc grid-item-uc-2'>
						<div className='grid-item-left-uc'>
							<h3>Play-to-Earn Games</h3>
							<p>Participate in competitive quests where you can earn SOL and other rewards.</p>
						</div>
						<div className='grid-item-img-uc img-2-uc'></div>
					</div>

					<div className='grid-item-uc grid-item-uc-3'>
						<div className='grid-item-left-uc'>
							<h3>Crypto Real Estate</h3>
							<p>Buy and sell virtual properties within Opulon—buildings, meeting rooms, and more.</p>
						</div>
						<div className='grid-item-img-uc img-3-uc'></div>
					</div>

					<div className='grid-item-uc grid-item-uc-4'>
						<div className='grid-item-left-uc'>
							<h3>NFT Marketplace</h3>
							<p>Trade unique avatars, accessories, and other digital assets within our NFT marketplace.</p>
						</div>
						<div className='grid-item-img-uc img-4-uc'></div>
					</div>


					
					
					
					
        </div>
      </div>
    </section>
  );
}

export default UseCase;

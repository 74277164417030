import React from 'react';
import Header from './Header/Header';
import HeroSection from './HeroSection/HeroSection';
import WhatIsOpulon from './WhatIsOpulon/WhatIsOpulon';
import WhyOpulon from './WhyOpulon/WhyOpulon';
import KeyFeatures from './KeyFeatures/KeyFeatures';
import CreateAvatar from './CreateAvatar/CreateAvatar';
import MeetTheTeam from './MeetTheTeam/MeetTheTeam';
import FAQ from './FAQ/FAQ';
import Footer from './Footer/Footer';
import UseCase from './UseCase/UseCase';

function HomePage() {
  return (
    <>
      <Header />
      <HeroSection />
      <WhatIsOpulon />
      <WhyOpulon />
      <KeyFeatures />
      <UseCase />
      <CreateAvatar />
      <MeetTheTeam />
      <FAQ />
      <Footer />
    </>
  );
}

export default HomePage;

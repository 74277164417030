import React from 'react';
import './KeyFeatures.css';
import icon1 from '../../assets/images/virtual-environ-icon.png';
import icon2 from '../../assets/images/blockchain-icon.png';
import icon3 from '../../assets/images/p2e-icon.png';
import icon4 from '../../assets/images/security-icon.png';

function KeyFeatures() {
  return (
    <section className="container-kf">
      <div className="overlay-kf"></div>
      <div className="inner-container-kf">
        <h2 className='title-kf'>Key Features of opulon</h2>
        <div className='grid-kf'>
					<div className='grid-item'>
						<img src={icon1} alt='icon' />
						<h3>Virtual Environment</h3>
						<p>
							Explore a fully interactive 3D world where you can trade, game, and connect with others in real-time.
						</p>
					</div>
					<div className='grid-item'>
						<img src={icon2} alt='icon' />
						<h3>Blockchain Activities</h3>
						<p>
						Engage in trading, staking, and NFTs with ease. All your blockchain activities, unified in one space.
						</p>
					</div>
					<div className='grid-item'>
						<img src={icon2} alt='icon' />
						<h3>Play-to-Earn</h3>
						<p>
							Earn real value through games and quests in Opulon. Your virtual achievements have tangible rewards.
						</p>
					</div>
					<div className='grid-item'>
						<img src={icon3} alt='icon' />
						<h3>User-Friendly Interface</h3>
						<p>
						Opulon is designed with a focus on user experience, making it easy for users of all levels to navigate and interact with the platform.
						</p>
					</div>
					<div className='grid-item'>
						<img src={icon4} alt='icon' />
						<h3>Security</h3>
						<p>
							Built on Solana, Opulon ensures your transactions are fast, secure, and cost-effective.
						</p>
					</div>
					
        </div>
      </div>
    </section>
  );
}

export default KeyFeatures;

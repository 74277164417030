import React, { useState, useEffect } from 'react';
import './Header.css';
import PopUp from '../PopUp/PopUp';

function Header() {
  const [scroll, setScroll] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [popUpIsOpen, setPopUpIsOpen] = useState(false);
  
  const handleScroll = () => {
    if (window.scrollY > 200) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  const toggleSideNav = () => {
    setIsOpen(!isOpen);
  };

  const togglePopup = () => {
    setPopUpIsOpen(!popUpIsOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`header ${scroll ? 'header-scrolled' : ''}`}>
      <div className="logo"></div>
      <nav>
        <ul>
          <li>
            <a href="/Opulon-white-paper.pdf" target="_blank" rel="noopener noreferrer">
              White Paper
            </a>
          </li>
          <li><button className="btn-primary" onClick={togglePopup}>Join Adventure</button></li>
        </ul>
      </nav>
      <div className="hamburger" onClick={toggleSideNav}></div>
      <div className={`side-nav ${isOpen ? 'side-nav-open' : ''}`}>
        <a href="javascript:void(0)" className="close-btn" onClick={toggleSideNav}>&times;</a>
        <a href="/Opulon-white-paper.pdf" target="_blank" rel="noopener noreferrer">
          White Paper
        </a>
        <a><button className="btn-primary" onClick={togglePopup}>Join Adventure</button></a>
      </div>
      <PopUp popUpIsOpen={popUpIsOpen} onClose={togglePopup} />
    </header>
  );
}

export default Header;

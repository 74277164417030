import React from 'react';
import './FAQ.css';
import icon1 from '../../assets/images/virtual-environ-icon.png';
import icon2 from '../../assets/images/blockchain-icon.png';
import icon3 from '../../assets/images/p2e-icon.png';
import icon4 from '../../assets/images/security-icon.png';

function FAQ() {
  return (
    <section className="container-faq">
      <div className="overlay-faq"></div>
      <div className="inner-container-faq">
        <h2 className='title-faq'>Frequently Asked Questions</h2>
        <div className='grid-faq'>
					<div className='grid-item-faq'>
						<h3>What is Opulon?</h3>
						<p>
						Opulon is an immersive virtual world built on the Solana blockchain. It's a platform where users can engage in various blockchain activities, from trading and staking to gaming and social interaction.
						</p>
					</div>
					<div className='grid-item-faq'>
						<h3>How do I access Opulon?</h3>
						<p>
							You can access Opulon by connecting your Solana wallet to the platform.
						</p>
					</div>
					<div className='grid-item-faq'>
						<h3>Is there a cost to use Opulon?</h3>
						<p>
							While there may be transaction fees associated with certain activities, Opulon itself is free to use.
						</p>
					</div>
					<div className='grid-item-faq'>
						<h3>Can I earn real money in Opulon?</h3>
						<p>
							Yes, Opulon offers various opportunities for users to earn points that can be converted to real cryptocurrency, through quests and community activities.
						</p>
					</div>
					<div className='grid-item-faq'>
						<h3>How does Opulon handle security and scalability?</h3>
						<p>
						Opulon leverages the scalability & security of the Solana blockchain to ensure a smooth user experience and safety of user data and transactions, even as the platform grows.
						</p>
					</div>
					<div className='grid-item-faq'>
						<h3>How does Opulon reward users' participation on the platform?</h3>
						<p>
							Opulon doesn't have a live token at the moment for incentivizing users. Participation is rewarded with OP points which can later be converted to real cryptocurrency based on the terms of completed quests.
						</p>
					</div>
					
        </div>
      </div>
    </section>
  );
}

export default FAQ;

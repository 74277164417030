import React from 'react';
import './WhyOpulon.css';
import img1 from '../../assets/images/vr-girl.png';
import img2 from '../../assets/images/vr-man.png';


function WhyOpulon() {
  return (
    <section className="container-wo">
      <div className="overlay-wo"></div>
      <div className="left-wo">
          <h2>Why Solana blockchain?</h2>
          <p className='subtitle-wo'>Optimal performance</p>
          <p className='desc'>Opulon is a virtual crypto world built on the Solana blockchain, designed to bring blockchain activities like trading, gaming, and social interaction into a unified and immersive environment.</p>
      </div>

      <div className="right-wo">
        <img src={img1} alt='' className='img-1' />
        <img src={img2} alt='' className='img-2' />
      </div>
    </section>
  );
}

export default WhyOpulon;

import React from 'react';
import './Footer.css';
import twitter from '../../assets/images/twitter-icon.png';
import telegram from '../../assets/images/telegram-icon.png';
import discord from '../../assets/images/discord-icon.png';

function Footer() {
  return (
    <footer className="footer">
      <h2>Join our social media community to stay updated</h2>
      <div className="social-icons-f">
        <a href='https://x.com/opulon_official' className='social-icon'><img src={twitter} alt='icon' target="_blank" rel="noopener noreferrer" /></a>
        <a href='https://x.com/opulon_official' className='social-icon'><img src={telegram} alt='icon' target="_blank" rel="noopener noreferrer" /></a>
        <a href='https://x.com/opulon_official' className='social-icon'><img src={discord} alt='icon' target="_blank" rel="noopener noreferrer" /></a>
      </div>
      <p className='copyright'>© 2024 Opulon. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
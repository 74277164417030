import React, { useState } from 'react';
import './HeroSection.css';
import PopUp from '../PopUp/PopUp';

function HeroSection() {
  const [popUpIsOpen, setPopUpIsOpen] = useState(false);
  
  const togglePopup = () => {
    setPopUpIsOpen(!popUpIsOpen);
  };

  return (
    <section className="hero-section">
      <div className="overlay"></div>
      <div className="content slide-in-left">
        <h1>Welcome to Opulon: An Immersive Virtual World Built on Solana</h1>
        <p>Trade, Play, and Connect in a Seamless Crypto Ecosystem</p>
        <div className="hero-buttons">
          <button className="btn-primary" onClick={togglePopup}>Join the Adventure</button>
          <button className="btn-secondary">See Demo</button>
        </div>
      </div>
      <div className="image-div slide-in-right"></div>
      <PopUp popUpIsOpen={popUpIsOpen} onClose={togglePopup} />
    </section>
  );
}

export default HeroSection;

import React from 'react';
import './MeetTheTeam.css';
import icon1 from '../../assets/images/twitter-icon.png';
import icon2 from '../../assets/images/linkedin-icon.png';

function MeetTheTeam() {
  return (
    <section className="container-mtt">
      <div className="overlay-mtt"></div>
      <div className="inner-container-mtt">
				<div>
					<h2 className='title-mtt'>Meet the team</h2>
					<p className='subtitle-mtt'>
						Our team brings together experts in blockchain, virtual reality, game design, and community building.
					</p>
				</div>
        <div className='grid-mtt'>
					<div className='grid-item-mtt'>
						<div className='team-image-mtt img-1-mtt'></div>
						<div className='details-mtt'>
							<div>
								<h3>Jude Chimaobi</h3>
								<p>Founder</p>
							</div>
							<div className="icon-box-mtt">
								<a href='#'><img src={icon1} alt='icon' /></a>
								<a href='#'><img src={icon2} alt='icon' /></a>
							</div>
						</div>
					</div>
					<div className='grid-item-mtt'>
						<div className='team-image-mtt img-2-mtt'></div>
						<div className='details-mtt'>
							<div>
								<h3>Chinenye</h3>
								<p>Project Manager</p>
							</div>
							<div className="icon-box-mtt">
								<a href='#'><img src={icon1} alt='icon' /></a>
								<a href='#'><img src={icon2} alt='icon' /></a>
							</div>
						</div>
					</div>
					<div className='grid-item-mtt'>
						<div className='team-image-mtt img-3-mtt'></div>
						<div className='details-mtt'>
							<div>
								<h3>Chuks</h3>
								<p>Graphics & UiUx Designer</p>
							</div>
							<div className="icon-box-mtt">
								<a href='#'><img src={icon1} alt='icon' /></a>
								<a href='#'><img src={icon2} alt='icon' /></a>
							</div>
						</div>
					</div>
					<div className='grid-item-mtt'>
						<div className='team-image-mtt img-4-mtt'></div>
						<div className='details-mtt'>
							<div>
								<h3>Daniel</h3>
								<p>Social Media Manager</p>
							</div>
							<div className="icon-box-mtt">
								<a href='#'><img src={icon1} alt='icon' /></a>
								<a href='#'><img src={icon2} alt='icon' /></a>
							</div>
						</div>
					</div>
					<div className='grid-item-mtt'>
						<div className='team-image-mtt img-5-mtt'></div>
						<div className='details-mtt'>
							<div>
								<h3>Bello</h3>
								<p>Developer</p>
							</div>
							<div className="icon-box-mtt">
								<a href='#'><img src={icon1} alt='icon' /></a>
								<a href='#'><img src={icon2} alt='icon' /></a>
							</div>
						</div>
					</div>
					
					
        </div>
      </div>
    </section>
  );
}

export default MeetTheTeam;

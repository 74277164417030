import React from 'react';
import './PopUp.css';
import twitter from '../../assets/images/twitter-icon.png';
import telegram from '../../assets/images/telegram-icon.png';
import discord from '../../assets/images/discord-icon.png';

const PopUp = ({ popUpIsOpen, onClose }) => {
  return (
    <div className={`popup-overlay ${popUpIsOpen ? 'fade-in' : ''}`} onClick={onClose}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <h2>Join the Adventure!</h2>
        <p>Welcome! We're excited to have you here. Follow our social media pages to get notified.</p>
        <div className='popup-icon-container'>
          <a href='https://x.com/opulon_official' className='popup-icon'><img src={twitter} alt='icon' target="_blank" rel="noopener noreferrer" /></a>
          <a href='https://x.com/opulon_official' className='social-icon'><img src={telegram} alt='icon' target="_blank" rel="noopener noreferrer" /></a>
          <a href='https://x.com/opulon_official' className='social-icon'><img src={discord} alt='icon' target="_blank" rel="noopener noreferrer" /></a>
        </div>
        <button className="popup-close-btn" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default PopUp;

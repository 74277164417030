import React from 'react';
import './CreateAvatar.css';


function CreateAvatar() {
  return (
    <section className="container-ca">
      <div className="overlay-ca"></div>
			<div className='inner-container-ca'>
				<div className="text-div-ca">
					<h2>Create, Customize and Own</h2>
					<p>
						You can create, own and customize your avatars, homes, and other virtual assets.
					</p>
				</div>
				<div className='sample-image-ca image-1'></div>
				<div className='sample-image-ca image-2'></div>
				<div className='sample-image-ca image-3'></div>
			</div>
    </section>
  );
}

export default CreateAvatar;

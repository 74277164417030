import React from 'react';
import './WhatIsOpulon.css';

function WhatIsOpulon() {
  return (
    <section className="container">
      <div className="overlay"></div>
      <div className="left">
        <div className='image-left'></div>
        <div className='image-right'></div>
      </div>
      <div className="right">
        <div className="inner-div">
          <h2>What is Opulon?</h2>
          <p>Opulon is a virtual crypto world built on the Solana blockchain, designed to bring blockchain activities like trading, gaming, and social interaction into a unified and immersive environment.</p>
        </div>
        <div className="inner-div">
          <h3 className='subtitle'>Key Benefits</h3>
          <div className="box-div">
            <div>
              Seamless integration of blockchain activities
            </div>
            <div>
              Immersive, interactive virtual world
            </div>
            <div>
              Secure and fast transactions with Solana
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhatIsOpulon;
